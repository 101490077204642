export function myRand(min, max){
    return Math.floor(Math.random() * (max - min +1) + min);
}

// 角度转弧度
export function angleToRadian(_angle){
    return 3.14/180*_angle
}
// 弧度转角度
export function radianToAngle(_radian){
    return 180/3.14*_radian
}

// 数组打乱顺序
export function  shuffleArray(array) {  
    for (let i = array.length - 1; i > 0; i--) {  
        const j = Math.floor(Math.random() * (i + 1));  
        [array[i], array[j]] = [array[j], array[i]];  
    }  
    return array;  
}  
// 获取数组中任一项数组
export function getArrayItems(arr, num) {
    //新建一个数组,将传入的数组复制过来,用于运算,而不要直接操作传入的数组;
    let temp_array = new Array();
    for (let index in arr) {
        temp_array.push(arr[index]);
    }
    //取出的数值项,保存在此数组
    let return_array = new Array();
    for (let i = 0; i<num; i++) {
        //判断如果数组还有可以取出的元素,以防下标越界
        if (temp_array.length>0) {
            //在数组中产生一个随机索引
            let arrIndex = Math.floor(Math.random()*temp_array.length);
            //将此随机索引的对应的数组元素值复制出来
            return_array[i] = temp_array[arrIndex];
            //然后删掉此索引的数组元素,这时候temp_array变为新的数组
            temp_array.splice(arrIndex, 1);
        } else {
            //数组中数据项取完后,退出循环,比如数组本来只有10项,但要求取出20项.
            break;
        }
    }
    return return_array;
}

// 获取Url参数
export function getUrlParam(paramName){
    let return_str = null
    const searchQuery = decodeURI(window.location.search) || ''
    if (searchQuery.indexOf('?') === 0 && searchQuery.indexOf('=') > 1) {
      const arrSource = unescape(searchQuery)
        .substring(1, searchQuery.length)
        .split('&')
        for(let i = 0;i < arrSource.length;i ++){
            if(arrSource[i].indexOf('=') > 0 && arrSource[i].split('=')[0].toLowerCase() === paramName.toLowerCase()){
                let return_arr = arrSource[i].split('=');
                return_str = return_arr[1];
                if(return_arr.length >2){
                    for(let j = 2;j < return_arr.length ;j ++){
                        return_str += "="
                    }
                }
                break;
            }
        }
    }
    return return_str;
}