import $ from "jquery"

// eslint-disable-next-line no-undef
let Api_Config_url = API_CONFIG;


export function interfaceAjaxPromise(url,param,type){
    return new Promise(function(resolve,reject){
        $.ajax({
            url: Api_Config_url+ url,
            headers: {
                "token": getTokens()
            },
            data: param || {},
            type: type || 'GET'
        }).done(function(data) {
            if(data.code === 600008) {
                tokenShiXiaoFun();
                resolve(data)
                return false
            }
            resolve(data)
        })
        .fail(function() {
            reject();
        });
    })
}

export function interfaceAjaxJsonPromise(url,param,type){
    return new Promise(function(resolve,reject){
        $.ajax({
            url: Api_Config_url+ url,
            headers: {
                token: getTokens()
            },
            contentType : "application/json",
            data: JSON.stringify(param) || JSON.stringify({}),
            type: type || 'GET'
        }).done(function(data) {
            // if(data.code !== 200 && data.code !== 600008) {
            //     data.erro = true;
            //     resolve(data)
            //     return
            // }
            // if(data.code === 600008) {
            //     tokenShiXiaoFun();
            //     return false
            // }
            resolve(data)
        })
        .fail(function() {
            reject();
        });
    })
}

function tokenShiXiaoFun(){
    delTokens();
    delRocheUser();
    delRocheUserType();
    window.location.replace("./index.html")
}


export function interfaceAjax(url,param,type){
    console.log(url,param,type)
    return $.ajax({
        url: Api_Config_url+ url,
        headers: {
            token: getTokens()
        },
        data: param || {},
        type: type || 'GET'
      });
}
export function interfaceAjaxJson(url,param,type){
    
    return $.ajax({
        url: Api_Config_url+ url,
        headers: {
            token: getTokens()
        },
        contentType : "application/json",
        data: JSON.stringify(param) || JSON.stringify({}),
        type: type || 'GET'
      });
}


export function getTokens(){
    let token = localStorage.getItem('roche_neo_token');
    if(!token){
        token = "";
    }
    return token;
}
export function setTokens(_val){
    localStorage.setItem('roche_neo_token',_val);
}
export function delTokens(){
    localStorage.removeItem('roche_neo_token');
}

export function getRocheUser(){
    let users = localStorage.getItem('roche_neo_user');
    if(!users){
        users = "";
    }
    return users;
}
export function setRocheUser(_val){
    localStorage.setItem('roche_neo_user',_val);
}
export function delRocheUser(){
    localStorage.removeItem('roche_neo_user');
}

export function getRocheUserType(){
    let usersType = localStorage.getItem('roche_neo_usertype');
    if(!usersType){
        usersType = "";
    }
    return usersType;
}
export function setRocheUserType(_val){
    localStorage.setItem('roche_neo_usertype',_val);
}
export function delRocheUserType(){
    localStorage.removeItem('roche_neo_usertype');
}


