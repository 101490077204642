import $ from "jquery"

// import "../css/reset.less"
// import "../css/index.less"

import { getTokens,setTokens,setRocheUser,setRocheUserType, interfaceAjaxPromise } from "./reques";
import { getUrlParam } from "./smallpack";
// import VConsole from "vconsole"
// new VConsole();

// eslint-disable-next-line no-undef
let urlencode = QIANMING_BASE64;

// eslint-disable-next-line no-undef
let useId = USEID

// eslint-disable-next-line no-undef
let wechat_url = WECHAT_URL;

$(function () {

    if(getTokens() !== ""){
      window.location.replace("./game.html")
    }else{
      let ticketValue = getUrlParam('ticket');
      if(ticketValue){
        console.log("有值，开始登录")
        
        interfaceAjaxPromise("wx/user/info",{ticket:ticketValue},"get").then(function(res){
            if(res.code !== 200){
              alert(res.msg);
              $(".pagecont").text(res.msg)
              return;
            }
            setTokens(res.data.token)
            setRocheUser(res.data.userId)
            setRocheUserType(res.data.isMain)
            window.location.replace("./game.html")
        })
      }else{
        console.log("啥也没有,去授权")
        console.log(urlencode,useId,wechat_url)
        // https://wechatportal-tst.roche-diagnostics.cn/ // 测试
        // https://wechatportal.roche-diagnostics.cn/     // 正式
        //     let useId = "172";
        //     let urlencode ="aHR0cHM6Ly9yb2NoZS1mZWFzdC5jc3NoYXZhcy5jbi9pbmRleHR3by5odG1sP3RpY2tldD0=" //encodeURIComponent(btoa("https://roche-feast.csshavas.cn/indextwo.html?ticket="));
        // let _href  = `https://wechatportal.roche-diagnostics.cn/japi/qiye/oauth/ticket/${useId}/${urlencode}`
        //     window.location.href = _href

        //  https://wechatportal-tst.roche-diagnostics.cn/japi/qiye/oauth/ticket/401/aHR0cHM6Ly9yb2NoZW5lby10ZXN0LmNzc2hhdmFzLmNuL2luZGV4Lmh0bWw_dGlja2V0PQ==
        let _href = `${wechat_url}japi/qiye/oauth/ticket/${useId}/${urlencode}`
        window.location.href = _href

      }

    }


});